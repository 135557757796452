import React from "react"
import {withPrefix} from "gatsby"
// import { Helmet } from "react-helmet"
// import { Link } from "gatsby"

import "../../styles/core.css"
import "../../styles/styles.css"
import Layout from "../../components/Layout"
import MenuOne from "../../components/MenuOne"
import MenuTwo from "../../components/MenuTwo"
import Content from "../../components/Content"
import Gallery from "../../components/Gallery"

const galImgs = ['/page-images/building_control_1_0.jpg']

export default function BuildingControl() {
  return (
    <Layout breadcrumbs={["Building Control"]}>
      <Gallery items={
      galImgs.map(image => ({
          original: withPrefix(image),
          thumbnail: withPrefix(image),
        })
      )}
    />

<Content>

<p><strong>Building Control Applications</strong></p>

<p>	The building control application is made when the scheme design drawings are further enhanced and expanded on with all of the construction detailing and specifications to permit assessment of the technical aspects of the design.</p>
<p>	This assessment includes the structural suitability, energy performance, health and safety in-use, fire safety, disabled access and a host of others.  The materials employed and how they are combined are also assessed.</p>
<p>	Any revisions to the information we provide to building control is carried out and approval is subsequently received.</p>
<p>	We have never been refused approval of a building control application made on behalf of our clients.</p>

</Content>

      <div class="region region-content-bottom">
        <MenuOne active="what-we-do" />
        <MenuTwo active="building-control" items={[
          "../initial-designs",
          "../planning-approvals",
          "../building-control",
          "../site-operations",
          "../other-services"
        ]} />
      </div>

  </Layout>

  )
}
